import React, { forwardRef } from "react";
import styled from "styled-components";

const StyledCard = styled.div<{
  responsive?: boolean;
}>`
  border: 1px solid var(--light-blue);
  border-radius: 2px;
  background: white;
  transition: transform 0.2s var(--cubic-in-out);

  width: min(21vw, 15vh);
  height: min(29.17vw, 20.83vh);
  padding-left: min(0.8vw, 0.6vh);
  padding-top: min(0.8vw, 0.6vh);
  padding-right: min(0.8vw, 0.6vh);
  padding-bottom: min(5.5vw, 4vh);
`;

type CardProps = {
  children: React.ReactNode;
  className?: string;
  responsive?: boolean;
};

const Card: React.ForwardRefRenderFunction<HTMLDivElement, CardProps> =
  forwardRef(({ children, className, responsive }: CardProps, ref) => {
    return (
      <StyledCard className={className} responsive={responsive} ref={ref}>
        {children}
      </StyledCard>
    );
  });

export default Card;
